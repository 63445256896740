.react-select-container {
}

.react-select__control {
  border: 1px solid #cccccc;
  padding-top: 8px;
  padding-bottom: 8px;
}

.react-select__control[data-focus] {
  border: 1px solid #cccccc;
  box-shadow: none;
}

.react-select__menu {
  box-shadow: 0px -2px 11px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.react-select__option {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #21191b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 0px 1px 0px 0px #d9d9d9;
}
.react-select__option:hover {
  background: #f2f6ff;
  color: #4358f6;
  font-weight: 600;
}

.scroll-bar-hide::-webkit-scrollbar {
  display: none!;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-bar-hide {
  -ms-overflow-style: none!; /* IE and Edge */
  scrollbar-width: none!; /* Firefox */
}

.profile-upload {
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
}

.blinking-text {
  color: #333;
  /* animation: blink 3s linear infinite; */
  border: 1px solid #cccccc !important;
  padding: 10px;
  border-radius: 10px;
  background-color: #fee8c8;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
