.bottomDrawerClass{
  outline: none;
  background: white;
  font-size: 1.6rem;
  width: 100%;
  display: flex;
   height: fit-content;
    overflow: auto;
  align-self: flex-end;
  flex-direction: column;
  z-index: 15;
  /* min-height: 47rem; */
  will-change: transform;
  /* transform: translate3d(0, 0, 0); */
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #fff;
  padding: 16px;
}