/* override mui colors */

h6[class="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-subtitle1"],
h6[class="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiPickersToolbarText-toolbarBtnSelected MuiTypography-subtitle1"] {
  background-color: white;
  color: #3f51b5;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-size: 20px !important;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
.MuiDialog-root{
  z-index: 1500 !important;
}